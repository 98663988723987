import React from "react"

import "./YoutubeVideo.scss"

interface YoutubeVideoProps {
  videoID: string
}

const YoutubeVideo = ({ videoID }: YoutubeVideoProps) => {
  return (
    <div className="youtube-video">
      <iframe
        width="560"
        height="315"
        src={"https://www.youtube.com/embed/" + videoID}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default YoutubeVideo
