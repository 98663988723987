import React from "react"
import logo from "./logo.svg"
import "./App.css"

import { YoutubeVideo, FacebookPost } from "./components"

function App() {
  return (
    <div className="App">
      <YoutubeVideo videoID="jrsb2aZLM6E" />
      <YoutubeVideo videoID="s3ScJ_FwaZk" />
      <FacebookPost
        src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fmaddi.pehrson%2Fposts%2F2206814499470664&show_text=true&width=500"
        width="500"
        height="562"
        scrolling="no"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      />
    </div>
  )
}

export default App
