import React from "react"

import "./FacebookPost.scss"

interface FacebookPostProps {
  src: string
  width: string
  height: string
  scrolling: string
  allow: string
}

const FacebookPost = ({
  src,
  width,
  height,
  scrolling,
  allow,
}: FacebookPostProps) => {
  return (
    <div className="facebook-post">
      <iframe
        src={src}
        width={width}
        height={height}
        scrolling={scrolling}
        frameBorder="0"
        allowFullScreen
        allow={allow}
      ></iframe>
    </div>
  )
}

export default FacebookPost
